html {
	min-height: 100%;

}
body{
	font-family: $font_primary;
	color: $text_c;
	img{
		max-width: 100%;
		display: block;
	}
}
a{
    transition: $transition_main_all;
}
#map {
	height: 400px;
	width: 100%;
}
.cherry_font{
    font-family: $cherry_font;
}
.lobster_font{
    font-family: $lobster_font;
}
.primary_font{
    font-family: $font_primary;
}

ul{
    list-style: outside none none;
    margin: 0;
    padding: 0
}
.open-dropdown {
    display: none;
}
.lace-bg{
    height: 48px;
    background-repeat: repeat-x;
    background-position: bottom center;
    &.lace-pink{
        background-image: url("../img/lace-secondary.png");
    }
    &.lace-secondary{
        background-image: url("../img/lace-secondary.png");
    }
    &.lace-white{
        background-image: url("../img/lace-white.png");
    }
    &.lace-primary{
        background-image: url("../img/lace-primary.png");
    }
}

.card{
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    border: 0;
    border-radius: 0;
}