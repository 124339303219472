.sidebar-widget {
    margin-bottom: 40px;
}

.widget-title {
    color: #333333;
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    overflow: hidden;
    padding-bottom: 10px;
    position: relative;
    text-transform: uppercase;
    &:after {
        background: $secondary none repeat scroll 0 0;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
    }
    a {
        &:hover {
            text-decoration: none;
            color: $secondary;
        }
    }
}

.sidebar-static-page {
    padding-top: 20px;
}

#profile_nav {
    .nav-item{
        border-bottom: 1px solid #e7e4dd;
    }
    .nav-link {
        &.active {
            display: flex;
            align-items:center;
            justify-content: space-between;
            color: $secondary;
            &:after {
                content: "\f105";
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
            }
        }
    }
}