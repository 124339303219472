/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.mobile-sidebar {

    width: 250px;
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 999;
    background: #fff;
    color: $text_c;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    &.m_nav {
        left: -300px;
        &.active {
            left: 0;
        }
        .dismiss-mobile-sidebar{
            right: 0;
        }
    }
    &.m_search {
        right: -300px;
        &.active {
            right: 0;
        }
        .dismiss-mobile-sidebar{
            left: 0;
        }
    }

    .logo-side-menu {
        img {
            width: 120px;
        }
    }
    .collapse-link {
        display: flex;
        justify-content: space-between;
    }
}

.dismiss-mobile-sidebar {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: $primary;
    color: #fff;
    position: absolute;
    top: 10px;

    cursor: pointer;
    transition: $transition_main_all;
}

.dismiss-mobile-sidebar:hover {
    background: #fff;
    color: $primary;
}

.overlay {
    top: 0;
    left: 0;
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.overlay.active {
    display: block;
    opacity: 1;
}

.mobile-sidebar .sidebar-header {
    padding: 20px 20px 0 20px;
    background: #fff;
    .logo-text {
        font-weight: 900;
        color: $primary;
    }
}

.mobile-sidebar ul.components {
    border-bottom: 1px solid $primary;
}

.mobile-sidebar {
    ul {
        li {
            a {
                padding: 10px;
                font-size: 1rem;
                display: block;
                &:hover, &:focus {
                    color: $primary;
                    background: #fff;
                    text-decoration: none;
                }
            }
        }
    }
}

.mobile-sidebar ul li.active > a,
a[aria-expanded="true"] {
    color: #fff;
    background: $primary;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

