/***
====================================================================
	Titles & Headers
====================================================================
 ***/
.section-header {
    border-top: 1px solid $secondary;
    padding-top: 20px;
    padding-bottom: 20px;
    &.no-top-border {
        border-top: none
    }
    &.bg-secondary-header {
        .section-title {
            color: #fff;
            .title-logo-text {
                color: #fff;
                text-shadow: 2px 2px 0 $primary;
            }
        }

    }
}

.section-title {
    font-size: 1.6rem;
    text-transform: uppercase;
    .title-logo-text {
        text-transform: none;
        color: $primary;
        font-family: $lobster_font;
        font-size: 1.8rem;
        text-shadow: 2px 2px 0 $secondary;
    }
}

.sec-part-title {
    font-size: 1.6rem;
    font-weight: 300;
    text-transform: uppercase;
}

.static-title {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 2rem;
    overflow: hidden;
    display: inline-block;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 20px;
    &:after {
        background: $secondary none repeat scroll 0 0;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &.blog-single-title {
        margin-bottom: 4px;
    }
    &.product-list-title {
        font-size: 20px;
    }
}

/***
====================================================================
	breadcrumb
====================================================================
 ***/
.breadcrumb-area {
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 60px;
    overflow: hidden;
    padding: 15px 0;
}

.breadcrumb-list {
    li {
        display: inline-block;
        font-size: 12px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        a {

            color: #333;
            &:hover {
                color: #C97178;
            }
        }
    }
}

/***
====================================================================
	pagination
====================================================================
 ***/
.pagination-wrap {
    margin-bottom: 40px;
    .page-item {
        .page-link {
            border-radius: 0;
        }
    }

}

/***
====================================================================
	Sections
====================================================================
 ***/
.banner-sec {
    a {
        display: block;
        margin-bottom: 20px;
    }
}

.blog-sec {
    .blog-sec-lace {
        margin-bottom: 20px;
    }
    .section-header {
        margin-bottom: 20px;
    }
    .bg-footer {
        margin-top: -50px;
        background-color: $primary;
        height: 40px;
    }

}

.map-wrap {
    background-color: $primary;
    padding-top: 50px;
}

.slick-slider-wrap {
    display: none;
}

.slick-slider-wrap.slick-initialized {
    display: block;
}

/***
====================================================================
	Blog Single
====================================================================
 ***/
.blog-single {
    .date {
        border-bottom: 1px solid $secondary;
        padding-bottom: 4px;
        margin-bottom: 20px;
    }
}

/***
====================================================================
	Product list
====================================================================
 ***/
.page-product-list {
    .breadcrumb-area {
        margin-bottom: 30px;
    }
}

.shop-item-filter {
    padding: 10px 0;
    border-bottom: 1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1;
    margin-bottom: 30px;
}

.orderby.nice-select {
    color: #333;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    width: 250px;
}

/***
====================================================================
	Product single
====================================================================
 ***/
.single-product-image-area {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}

.single-product-info-area .product-name {
    font-size: 26px;
    font-weight: 300;
}

.single-product-info-area .ratings i {
    color: #ff9900;
}

.single-product-info-area .product-desc > p {
    font-size: 14px;
    margin: 20px 0;
    color: #666;
}

.single-product-info-area .product-price-box {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 20px;
    text-align: left;
    .new-price {
        font-weight: 900;
    }
    .old-price {
        text-decoration: line-through;
        color: lighten($text_c, 30%);
    }
}

.single-product-info-area .product-meta {
    border-top: 1px solid #ebebeb;
    margin: 20px 0 10px;
    padding-top: 15px;
    text-transform: capitalize;
    .posted-meta-inner a {
        color: #666666;
        font-size: 14px;
        &:hover {
            color: $secondary;
        }
    }
}

.description-content {
    padding: 20px 0;
}

.similar-products-area {
    padding-top: 120px;
    padding-bottom: 60px;
}

/***
====================================================================
	Product single cart-plus-minus BTN
====================================================================
 ***/
.quick-add-to-cart .quantity label {
    display: block;
    margin-bottom: 0.375rem;
    color: $text_c;
    font-weight: 900;
    text-transform: uppercase;
}

.cart-plus-minus {
    float: left;
    margin-right: 2px;
    position: relative;
    width: 76px;
}

.cart-plus-minus-box {
    background: $primary none repeat scroll 0 0;
    border-image: none;
    border-style: none solid none none;
    border-width: medium 1px medium medium;
    color: #ffffff;
    height: 46px;
    text-align: center;
    width: 48px;

}

.dec.qtybutton {
    bottom: 0;
    right: 0;
}

.inc.qtybutton {
    right: 0;
    top: 0;
}

.dec.qtybutton, .inc.qtybutton {
    background: $primary none repeat scroll 0 0;
    color: #ffffff;
    cursor: pointer;
    height: 23px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    width: 28px;
}

.single-product-info-area {
    .add-to-cart {
        background: $primary;
        border: medium none;
        color: #ffffff;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        height: 46px;
        line-height: 46px;

        padding: 0 25px;
        text-transform: uppercase;
        -webkit-transition: all 300ms ease 0s;
        transition: all 300ms ease 0s;
    }
    .wishlist-title {
        color: $text_c;
        font-weight: 900;
        text-transform: uppercase;
    }
    .btn-wishlist {
        padding: 0;
        height: 46px;
        width: 80px;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        &:hover, &:focus {
            color: $secondary;
        }
    }
}

.dec.qtybutton:hover, .inc.qtybutton:hover, .quick-add-to-cart .add-to-cart:hover {
    background: $secondary;
}

/***
====================================================================
	Product list
====================================================================
 ***/
.product-list-table {

    td {
        vertical-align: middle;
    }

    .product-image {
        width: 60px;
    }
}

/***
====================================================================
	 Cart page
====================================================================
 ***/
.cart-table {

    //margin-bottom: 30px;
}

.cart-table table.table-min-content.shop-table {
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
}

.cart-table .table-min-content {
    th {
        background: #f6f6f6 none repeat scroll 0 0;

        border-right: 1px solid #e7e4dd;
        font-size: 1.07em;
        font-weight: 700;
        padding: 10px;
        text-align: center;
        text-transform: capitalize;
        vertical-align: middle;
        white-space: nowrap;

    }
    td {
        border-bottom: 1px solid #e7e4dd;
        border-right: 1px solid #e7e4dd;
        border-top: 0 none;
        font-size: 1.142em;
        font-weight: 500;

        text-align: center;
        vertical-align: middle;
        &.product-remove {
            border-left: 1px solid #dddddd;
        }
    }
}

.cart-table .shop-table td.product-remove > a {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;

    border-radius: 50%;
    color: #333333;
    display: inline-block;
    font-size: 20px;
    height: 30px;
    line-height: 26px;
    width: 30px;
    transition: $transition_main_all;
    &:hover {
        color: #fff;
        background-color: $secondary;
    }
    .fa {
        line-height: 29px;
    }
}

.cart-table .shop-table td.c-product-thumbnail img {

    width: 100px;
}

.cart-table .shop-table td.c-product-name a {
    color: #333333;
    font-size: 15px;
}

.cart-table .shop-table td.c-product-name a:hover {
    color: $secondary;
}

.cart-table .shop-table td.c-price {
    color: $secondary;
    font-weight: 700;
    font-size: 15px;
}

.cart-table .shop-table td label {
    display: block;
    font-size: 15px;
    font-weight: normal;
    margin: 0 0 5px;
}

.cart-table .shop-table td .c-input-text {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 2px solid #e7e4dd;
    border-radius: 0;
    color: #4c4c4c;
    font-size: 15px;
    font-weight: normal;
    height: 42px;
    padding: 0 0 0 10px;
    text-align: center;
    width: 60px;
}

.cart-table .shop-table td.product-add-to-cart .total-amount {
    color: $secondary;
    font-weight: 700;
}

.cart-table .table-min-content .table-bottom td {
    border-bottom: medium none;
    border-right: 0 none;
}

.cart-table .table-min-content .table-bottom td {
    padding: 10px 0;
}

.coupon .input-text {
    border: 1px solid #d3ced2;
    box-sizing: border-box;
    line-height: 1;
    margin: 0 4px 0 0;
    outline: 0 none;
    padding: 6px 6px 5px;
    height: 42px;
}

/*-- cart-totals --*/
.cart-collaterals .cart-totals .cart-totals-table {
    margin-bottom: 10px;
    width: 100%;
}

.cart-collaterals .cart-totals {
    border: 1px solid #e7e4dd;
    padding: 30px;
    margin-bottom: 50px;
    background-color: #f6f6f6;

}

.cart-collaterals .cart-totals > h2 {
    font-size: 1.428rem;
    font-weight: 900;
    line-height: 30px;
    margin-bottom: 20px;
    padding: 10px 0;
    text-transform: uppercase;
}

.cart-total .cart-totals-table {
    border: medium none;
    float: right;
    margin: 0 0 10px;
    text-align: right;
    color: #4c4c4c;
}

.cart-totals-table th {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    font-weight: 400;
    padding: 0;
    text-align: left;
    text-transform: capitalize;
    vertical-align: top;
}

.cart-totals-table td {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0 !important;
    border: medium none;
    font-size: 1.142rem;
    font-weight: 900;
    padding: 0 0 12px;
    text-align: right;
    vertical-align: top;
}

.cart-totals-table .cart-subtotal .amount {
    font-weight: 900;
}

.cart-collaterals .cart-totals-table tr.shipping {
    border-bottom: 1px solid #e7e4dd;
}

.cart-totals-table .order-total th {
    font-size: 2rem;
    font-weight: 700;
    padding: 10px 0;
}

.cart-totals-table .order-total td {
    color: $secondary;
    font-size: 1.8rem;
    font-weight: 700;
    padding: 10px 0;
}

.cart-totals-table .shipping a, .lost-password {
    color: #ff5c00;
}

.lost-password {

    margin: 12px 0 0 0;
}

.cart-totals-table .shipping a:hover, .lost-password:hover {
    color: $secondary;
}

.coupon-info .button-login, .login-Register-info .button-login {
    float: inherit;
}

.checkout-button.button:hover,
.checkout-payment .button-continue-payment:hover,
.coupon-info .button-login:hover,
.login-Register-info .button-login:hover {
    background: $primary none repeat scroll 0 0;
    text-decoration: none;
}

.table-hover tbody tr:hover {
    background-color: #f6f6f6;
}

/***
====================================================================
	 Checkout page
====================================================================
 ***/
.coupon-accordion > h3 {
    background: #f7f6f7 none repeat scroll 0 0;
    border-top: 3px solid $secondary;
    color: #515151;
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 2em;
    padding: 1em 2em 1em 3.5em;
}

.coupon-accordion h3 span.coupon {
    color: #ff5c00;
    cursor: pointer;
}

.coupon-accordion h3 span.coupon:hover {
    color: #C97178;
}

.coupon-content, .login-Register-info {
    border: 1px solid #d3ced2;
    border-radius: 5px;
    margin: 2em 0;
    padding: 20px;
}

.customer-login-register > h3 {
    line-height: 28px;
}

.coupon-content {

}

.coupon-info p.coupon-input {
    margin: 0 0 10px;
}

.coupon-info p.form-row-first {
    float: left;
    width: 47%;
}

.coupon-info p.form-row-last {
    float: right;
    width: 47%;
}

.coupon-info p.coupon-input > label {
    display: block;
    line-height: 2;
    margin-bottom: 5px;
}

.coupon-input > input, .checkout-coupon > input, .single-form-row > input, .single-form-row textarea {
    border: 1px solid #e5e5e5;
    height: 42px;
    padding: 0 0 0 10px;
    width: 100%;
}

.single-form-row textarea {
    height: 100px;
    padding: 10px;
    line-height: 1.5;
}

.coupon-input .required {
    color: #ff0000;
}

.coupon-info .button-login, .login-Register-info .button-login {
    margin: 5px 10px 0 0;
}

.coupon-info label span, .login-Register-info label span {
    margin-left: 5px;
}

.checkout-coupon {
    margin: 0;
}

.checkout-coupon > input {
    width: auto;
}

.checkout-coupon .button-apply-coupon {
    background: #333333 none repeat scroll 0 0;
    border: medium none;
    color: #ffffff;
    cursor: pointer;
    line-height: 42px;
    margin-left: 5px;
    padding: 0 25px;
}

.checkout-coupon .button-apply-coupon:hover {
    background: #1a1a1a none repeat scroll 0 0;
}

.nice-select {
    float: inherit;
}

.account-create .creat-pass {
    color: #aa0000;
}

.account-create input.input-text {
    border-color: #aa0000;
}

.account-create, .ship-box-info {
    display: none;
}

.login-Register-info .button-login:hover {
    background: #752C32;
}

/*-- checkbox-form --*/
.checkout-area h3.shoping-checkboxt-title {
    border-bottom: 1px solid #e7e4dd;
    font-size: 1.4em;
    font-weight: 400;
    margin: 0 0 20px;
    padding-bottom: 10px;
    text-transform: none;
    width: 100%;
}

.checkbox-form .single-form-row {
    margin: 0 0 30px;
}

.single-form-row .required {
    border: 0 none;
    color: #ff0000;
    font-weight: 700;
    cursor: help;
}

.single-form-row.checkout-area {
    margin-bottom: 5px;
}

table.checkout-review-order-table {
    width: 100%;
}

.checkout-review-order-table thead th, .checkout-review-order-table tbody td,
.checkout-review-order-table tfoot tr th, .checkout-review-order-table tfoot tr td {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-bottom: 1px solid #dcd8ce;
    border-right: medium none;
    border-top: medium none;
    font-size: 14px;
    padding: 15px 0;
    text-align: center;
}

.checkout-review-order-table tfoot tr td {
    font-weight: 700;
}

.checkout-review-order-table tfoot tr.order-total td, .checkout-review-order-table tfoot tr.order-total th {
    border-bottom: medium none;
    font-size: 18px;
}

.checkout-review-order-table tfoot tr.order-total td span {
    color: #C97178;
}

.payment_methods {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px;
    background-color: #f6f6f6;
    border: 1px solid #e7e4dd;
}

.payment_methods > p {
    margin: 0;
    font-size: 13px;
}

.payment_methods label {
    font-size: 14px;
    margin: 0;
}

.checkout-payment .button-continue-payment {
    float: right;
    margin-bottom: 30px;
}




/***
====================================================================
	 404 page
====================================================================
 ***/
.search-error-wrapper {
    padding: 90px 0;
    text-align: center;
}
.error404 .search-error-wrapper h1 {
    color: $primary;
    font-size: 200px;
    font-weight: 900;
    letter-spacing: 10px;
    line-height: 140px;
    margin: 0 0 60px;
}
.error404 .search-error-wrapper h2 {
    color: #4c4c4c;
    font-size: 35px;
    text-transform: uppercase;
}
.error404 .search-error-wrapper p {
    color: #333;
    font-size: 22px;
    font-weight: 300;
    margin: 20px auto 30px;
    text-align: center;
    width: 60%;
}
.error404 .search-error-wrapper .error-form .error-form-input {
    background: #f8f8f8 none repeat scroll 0 0;
    border: 1px solid #e9e9e9;
    border-radius: .3rem;
    height: 50px;
    margin: 0 auto;
    position: relative;
    width: 450px;
}
.error404 .search-error-wrapper .error-form .error-form-input .error-input-text {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 0 none;
    height: 100%;
    outline: medium none;
    padding-left: 20px;
    padding-right: 80px;
    width: 100%;
}
.error404 .search-error-wrapper .error-form .error-form-input .error-s-button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 0 none;
    color: #4f4f4f;
    font-size: 18px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.error404 .search-error-wrapper .error-form .error-form-input .error-s-button:hover {
    color: $secondary;
}
.error404 .search-error-wrapper a.home-bacck-button {
    background: $secondary none repeat scroll 0 0;
    border-radius: 35px;
    box-shadow: none;
    color: #ffffff;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    margin-top: 30px;
    padding: 0 30px;
    text-shadow: none;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    white-space: nowrap;
}
.error404 .search-error-wrapper a.home-bacck-button:hover {
    background: $secondary;
}