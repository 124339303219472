.page-header {

    .header-top {
        position: relative;
        z-index: 5;
    }
    .header-lace {
        margin-top: -7px;
        position: absolute;
        width: 100%;
        height: 48px;
        z-index: 2;
    }
}

.header-mid-area {
    padding-top: 24px;
    .logo {
        a {
            transition: $transition_main_all;
            &:hover {
                text-decoration: none;
                .logo-text {
                    color: $secondary;
                }

            }
        }
        img {
            width: 200px;
        }

        .logo-text {
            transition: $transition_main_all;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 24px;
            color: $primary;

        }
    }
}

.search-form {
    .form-control {
        border-color: $primary;
        background-color: #f7f3f7;

    }
}

.mid-right-wrapper {
    padding-top: 30px;

}

.user-info-top {
    font-family: $cherry_font;
    font-weight: 700;
    padding-bottom: 15px;
    .spacer {
        color: $gray-500;
        margin: 0 10px;
    }
}

/*-----------------------------------
   04. main-menu-area css
--------------------------------------*/
.solidblockmenu.sticky {

    @include breakpoint($min992) {
        -webkit-animation: 600ms ease-in-out 0s normal none 1 running fadeInDown;
        animation: 600ms ease-in-out 0s normal none 1 running fadeInDown;
        background: #f4f4f4 none repeat scroll 0 0;
        left: 0;
        margin: auto;
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
        padding: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 800;
    }
}

.main-menu-area.sticky .main-menu.menu-area-five ul li {
    padding: 22px 8px;
}

.main-menu-area.sticky .main-menu.menu-area-five li ul.mega-menu > li ul li,
.main-menu-area.sticky .main-menu.menu-area-five li ul.mega-menu-two > li ul li {
    padding: 0;
}

#id {
    height: 2000px;
}

.main-menu-area {
    border-bottom: 1px solid $secondary;
    border-top: 1px solid $secondary;
    .logo-navbar {
        img {
            width: 60px;
        }
        .logo-text {
            font-weight: 900;
        }
    }
}

.navbar-bioena {
    padding: 0;
    .navbar-nav {
        .nav-link {
            padding: .5rem .75rem;
            font-family: $cherry_font;
            font-size: 1.2rem;
            font-weight: 700;
            @include breakpoint($min992) {
                padding: 1rem 1.4rem;
            }
            &:hover, &:focus {
                background-color: $secondary;
                color: #fff;
            }
            &.dropdown-toggle{
                padding-right: 2rem;
            }
        }
    }

    .dropdown-menu {
        background-color: $primary;
        margin: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        .dropdown-item {
            color: #fff;
            padding: .75rem 1.5rem;
            transition: $transition_main_all;
            &:hover, &:focus {
                background-color: $secondary;
            }
        }
    }
    .nav-item {
        &.dropdown {
            &.show {
                .nav-link {
                    background-color: $primary;
                    color: #fff;
                }
            }
        }
    }
}

.nav-item.mega-menu-dropdown {
    position: static;
    .nav-link {
        position: relative;
        &.dropdown-toggle:after {
            right: 15px;
        }
    }
}

.nav-link {
    &.dropdown-toggle:after {
        right: 15px;
    }
}

.main-menu-area .main-menu .mega-menu {

    //background: #ffffff none repeat scroll 0 0;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    //display: none;
    left: 0;
    padding: 30px 0;
    position: absolute;
    right: 15px;

    top: 100%;
    width: 100%;
    z-index: 999;
}

.main-menu-area .main-menu .mega-menu > li {
    float: left;
    padding: 0 30px 0;
    width: 25%;
    margin: 0;
}

.main-menu-area .main-menu .mega-menu-two > li {
    width: 33.333%;
}

.main-menu-area .main-menu li ul.mega-menu > li a {
    color: #333333;
    display: block;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 35px;
    padding: 0;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;

}

.main-menu-area .main-menu .mega-menu > li ul li {
    display: block;
    margin: 0;
    padding: 0;
    text-align: left;
}

.main-menu-area .main-menu li ul.mega-menu > li ul li a {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 28px;
    padding: 0;
    text-transform: capitalize;
}

.main-menu-area .main-menu li ul.mega-menu > li a:hover,
.main-menu-area .main-menu li ul.mega-menu > li ul li a:hover,
.main-menu-area .main-menu li ul.mega-menu-two > li a:hover,
.main-menu li ul.mega-menu-two > li ul li a:hover {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    color: #c97178;
}