.blog-prev {
    background-color: transparent;
    margin-bottom: 30px;
    .blog-image {
        position: relative;
        //box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
        margin-bottom: 4px;
        .blog-date {
            position: absolute;
            bottom: -16px;
            right: 10px;
            span {
                border-radius: 30px;
                padding: 4px 16px;
                font-size: 14px;
                display: block;
                background-color: $secondary;
                color: #fff;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
                text-align: center;
            }
        }
        .blog-action {
            a {
                background: $secondary;
                bottom: 0;
                color: #fff;
                font-size: 15px;
                height: 38px;
                left: 0;
                line-height: 38px;
                margin: auto;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
                transition: all .3s ease-in-out 0s;
                width: 38px;
                transform: scale(.5) translateY(15px);
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    .blog-info {
        background-color: #fff;
        padding: 1.25rem;
        .title {
            font-size: 1.2rem;
            font-family: $cherry_font;
            margin-bottom: 10px;
            a {
                color: $text_c;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .text {
            font-size: .9rem;
        }
    }
    &:hover {
        .blog-action {
            a {
                opacity: 1;
                visibility: visible;
                transform: scale(1) translateY(0);
            }
        }
    }

}

.blog-prev-small {
    margin-bottom: 10px;
    border-bottom: 1px solid #f1f1f1;
    .body{
        background-color: #f7f7f7;
        border-top: 1px solid $secondary;
    }
    .title {
        font-size: 1rem;
        padding: 10px;
        margin: 0;
        a {
            color: $text_c;
            &:hover {
                color: $secondary;
                text-decoration: none;
            }
        }
    }
}