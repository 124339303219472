.product-prev {
    overflow: hidden;
    position: relative;
    //margin-top: 15px;
    margin-bottom: 30px;
    .product-image {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
        position: relative;
        //overflow: hidden;
        a {
            display: block;
            img {
                display: block;
                width: 100%;
            }
        }
        .sale-sticker {

            color: #ffffff;
            font-weight: 900;
            text-shadow: 1px 1px 1px rgba(#000, .4);
            display: block;
            font-size: 12px;
            left: -30px;
            padding: 25px 0 2px;
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            top: -8px;

            transform: rotate(-45deg);
            width: 90px;
            &.novo-sticker {
                background-color: $secondary;
            }
            &.akcija-sticker {
                background-color: $primary;
            }
        }
    }
    .product-action > a {
        background: $secondary;
        bottom: 0;
        color: #ffffff;
        font-size: 15px;
        height: 38px;
        left: 0;
        line-height: 38px;
        margin: auto;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        width: 38px;
        transform: scale(0.5) translateY(15px);
        opacity: 0;
        visibility: hidden;
    }
    .product-wishlist {
        position: absolute;
        top: 6px;
        right: 10px;
        a {
            color: $primary;
            .fa {
                padding: 10px;
                font-size: 20px;
            }
            &:hover {
                color: $secondary;
            }
        }

    }
    .product-chart {
        position: absolute;
        bottom: -16px;
        right: 10px;
        a {
            border-radius: 30px;
            display: block;
            background-color: $secondary;
            color: #fff;

            box-shadow: $box_shadow_2;
            text-align: center;
            z-index: 10;
            .fa {
                padding: 6px 20px;
                color: #fff;
                font-size: 20px;
            }
            &:hover {
                background-color: $primary;
            }
        }

    }
    &:hover .product-action > a {
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(0px);
    }
    .single-product-info {
        background-color: #f7f7f7;
        text-align: center;
        padding: 1.25rem;
        .header {
            height: 40px;
            padding: 0 5px;
            overflow: hidden;
            margin-bottom: 10px;
        }
        .title {
            margin-bottom: 0;
            font-size: 1rem;
            a {
                color: $text_c;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .product-price-box {
            .price {
                padding: 0 5px;
                &.old-price {
                    color: lighten($text_c, 30%);
                    text-decoration: line-through;
                }
                &.new-price {
                    color: $text_c;
                    font-weight: 900;
                }
            }
        }
    }
}

.product-prev-slide {
    padding: 0 15px;
}

.product-prev-slide-nav {
    width: 100px;
    height: 40px;

    position: relative;
    .prod_prev-slick-next,
    .prod_prev-slick-prev {
        position: absolute;
        transition: $transition_main_all;
        border-radius: 40px;
        text-align: center;
        width: 40px;
        height: 40px;
        line-height: 38px;
        border: 1px solid $secondary;
        outline: none;
        color: $secondary;
        background-color: transparent;
        box-shadow: none;
        &:hover, &:focus {
            cursor: pointer;
            background-color: $secondary;
            color: #fff;
        }
    }
    .prod_prev-slick-next {
        right: 0;
    }
    .prod_prev-slick-prev {
        left: 0;
    }
}

.product-prev-small {
    .product-image {
        width: 100px;
    }
    .title{
        font-size: 14px;
    }
    .price{
        font-size: 14px;
        &.old-price {
            white-space: nowrap;
            color: lighten($text_c, 30%);
            text-decoration: line-through;
        }
        &.new-price {
            white-space: nowrap;
            color: $text_c;
            font-weight: 900;
        }
    }
}