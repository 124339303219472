
label {
    &.error {
        color: $danger;
        font-size: 14px;
    }
}

.form-wrap {
    .form-control {
        border-radius: 0;
        font-size: 14px;
        height: 45px;
        padding-left: 15px;
        border: 1px solid #e7e4dd;

    }
    textarea {
        &.form-control {
            height: auto;
        }
    }

    label {
        display: inline-block;
        margin-bottom: .3rem;
        color: #666;
    }
    .order-type-wrap{
        label{
            color: #000;
        }
    }

    .form-part {
        margin-bottom: 30px;
    }
    .form-part-title {
        .form-check-inline {
            display: inline;
        }
    }
}

.contact-title > h3 {
    font-size: 24px;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.contact-input {
    display: block;
    margin: 0 -10px;
    overflow: hidden;
}

.contact-inner {

    margin: 0 0 20px;
    padding: 0 10px;
    width: 100%;
    @include breakpoint($min768) {
        float: left;
        margin: 0 0 20px;
        padding: 0 10px;
        width: 50%;
    }
}

.contact-inner.contact-message textarea, .contact-inner.contact-message {
    width: 100%;
}

.contact-page-form .contact-inner > input, .contact-inner.contact-message textarea {
    border: 1px solid #e7e4dd;
    color: #999999;
    font-size: 14px;
    height: 45px;
    padding-left: 15px;
    width: 100%;
}

.contact-inner.contact-message textarea {
    height: 135px;
    padding: 15px;
}

.form-messege {
    .error {
        color: #ff0000;
        font-style: italic;
        margin-top: 10px;
    }
    .success {
        color: #008000;
        font-style: italic;
        margin-top: 10px;
    }
}

.contact-submit-btn .submit-btn {
    background: $primary;
    border: 0 none;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: 47px;
    line-height: 47px;
    padding: 0 25px;
    text-transform: capitalize;
    transition: $transition_main_all;
}

.contact-submit-btn .submit-btn:hover {
    background: $secondary;
}

.table-hover tbody tr:hover {
    background-color: #F6F6F6;
}

.contact-infor {
    background: #f2f2f2 none repeat scroll 0 0;
    padding: 20px 15px;
}

.contact-dec > p, .contact-address {
    margin-bottom: 30px;
}

.contact-address > ul {
    max-width: 380px;
}

.contact-address li {
    border-top: 1px solid #e4e4e4;
    font-size: 14px;
    padding: 10px 0;
}

.contact-address ul li i {
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
}

.work-hours > h3 {
    font-size: 24px;
    margin: 0 0 5px;
}

.account-login-form {
    background: white none repeat scroll 0 0;
    //box-shadow: $box_shadow_1;
    padding: 30px 30px 20px;
}

.login-form-container {
    padding: 40px 60px;
    //box-shadow: $box_shadow_1;
    background: #fff;
    margin-bottom: 30px;
    border: 1px solid rgba(0, 0, 0, .125);
    padding-bottom: 20px;
}

.input-radio .custom-radio input {
    width: auto;
    height: 11px;
}

.account-login-form input {
    background: #ebebeb none repeat scroll 0 0;
    border: 0 none;
    color: #7d7d7d;
    height: 35px;
    margin-bottom: 20px;
    padding: 8px 15px;
    width: 100%;
}