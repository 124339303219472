
.shopping-cart li {
    position: relative;
}

.shopping-cart ul li > a {
    color: #ffffff;
    display: block;
    font-size: 16px;
    //line-height: 40px;

    padding-right: 50px;
    position: relative;
    text-align: right;
    text-decoration: none;
    //text-transform: uppercase;
}

.shopping-bag-wrap {
    position: absolute;
    right: 0;
    top: -9px;
    width: 40px;
    height: 32px;
}

#shopping_bag {
    path {
        fill: #fff;
    }
}

.shopping-cart span.item-total {
    color: $primary;
    display: block;
    line-height: 1;
    font-size: 18px;
    width: 40px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 12px;
}

.shopping-cart ul li .shopping-cart-wrapper {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 0 20px;
    position: absolute;
    right: 0;
    width: 370px;
    //top: 100%;
    top: 32px;
    z-index: 999;
}

.shopping-cart li .shopping-cart-wrapper ul li {
    border-bottom: 1px solid #ededed;
    padding: 20px 0;
}

.shopping-cart .shoping-cart-image {
    float: left;
    margin: 0 20px 0 0;
    position: relative;
    width: 72px;
}

.shopping-cart .shoping-cart-image .cart-sticker {
    background: $primary none repeat scroll 0 0;
    border-radius: 100%;
    color: #ffffff;
    font-size: 12px;
    left: 5px;
    line-height: 23px;
    min-width: 25px;
    padding: 2px 0 0;
    position: absolute;
    text-align: center;
    top: 5px;
}

.shopping-cart .shoping-product-details h3 {
    color: #222222;
    display: block;
    font-size: 13.5px;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
    word-wrap: break-word;
}

.shopping-cart .cart-product-price {
    span {
        color: $primary;
        &.val {
            color: $primary;
            margin: 10px 0 0;
            font-weight: 900;
        }
    }

    //display: block;

}

.product-size > span {
    color: #666666;
    display: block;
    font-size: 12px;
    margin: 5px 0 0;
}

.shopping-cart .product-color > span {
    color: #666666;
    display: block;
    font-size: 12px;
}

.shopping-cart .cart-total h5 {
    color: #414141;
    font-size: 14px;
    line-height: 25px;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
}

.shopping-cart .cart-total span {

    font-size: 16px;
}

.shopping-cart .shoping-checkout .cart-btn {
    border-radius: 0;
    color: #fff;
    margin-bottom: 20px;
    //color: #ffffff;
    //display: block;
    //font-weight: 600;
    //height: 40px;
    //line-height: 38px;
    //margin: 20px 0;
    //text-align: center;
    //text-transform: uppercase;
    //-webkit-transition: all 0.3s ease-in-out;
    //transition: all 0.3s ease-in-out;
}

.shopping-cart .shoping-checkout .cart-btn:hover {
    //background: #752c32;
    //color: #ffffff;
}
