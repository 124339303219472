/*---------------------------------------------
   05. slider-area css
----------------------------------------------*/
#home_slider{
    margin-bottom: 20px;
}
.slider-wrapper {
    background-size: cover;
}

.home_slider-inner {
    position: relative;
    .slider-text {
        position: absolute;
        top: 120px;
        left: 120px;
    }
}

.slider-text .slider-bg-text {
    position: relative;
    text-align: center;
}

.slider-text .slider-bg-text .title {
    font-family: $lobster_font;
    color: #ffffff;
    font-size: 150px;
    margin: 0;
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    //text-transform: uppercase;
}

.slider-text .slider-text-info {
    margin-top: -138px;
    position: relative;
    text-align: center;
    z-index: 1;
}

.slider-text .slider-text-info > .title {
    color: #ffffff;
    font-size: 90px;
    //font-weight: 900;
    //text-transform: uppercase;
    margin: 0;
    font-family: $lobster_font;
    text-shadow: 4px 4px 0px $secondary;
}

.slider-text .slider-text-info > p {
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-top: 0;
    position: relative;
    margin-bottom: 0;
    font-weight: 900;

    text-shadow: 2px 2px 0px $secondary;
}

//.slider-text.style4 {
//    padding: 280px 0;
//}
//.slider-text.style4 .slider-text-info {
//    text-align: center;
//}
//.slider-text.style4 .slider-text-info > p {
//    color: #fff;
//    font-size: 18px;
//    letter-spacing: 8px;
//    margin: 0;
//    text-transform: uppercase;
//}
//.slider-text.style4 .slider-text-info h1 {
//    font-size: 50px;
//    letter-spacing: 10px;
//    margin-bottom: 25px;
//    color: #fff
//}
//.slider-text.style4.style5 .slider-text-info > p {
//    color: #222;
//}
//.slider-text.style4.style5 .slider-text-info h1 {
//    color: #222
//}
//.slider-text.style4 .slider-text-info .slier-shop-btn {
//    background: #ffffff none repeat scroll 0 0;
//    color: #222222;
//    display: inline-block;
//    font-size: 16px;
//    height: 40px;
//    line-height: 40px;
//    padding: 0 20px;
//    text-transform: uppercase;
//    -webkit-transition: 0.3s ease-in-out;
//    transition: 0.3s ease-in-out;
//}
//.slider-text.style4 .slider-text-info .slier-shop-btn:hover {
//    background: #222;
//    color: #fff;
//}
//.slider-text.slier-6 {
//    padding: 160px 0 190px;
//}
//.slider-text-5.style-1 {
//    padding: 230px 0;
//}
//.slider-text-5.style-1 .slider-text-info > p {
//    color: #ffffff;
//    font-size: 28px;
//    font-weight: 400;
//    margin-bottom: 5px;
//}
//.slider-text-5.style-1 .slider-text-info > h2 {
//    color: #ffffff;
//    font-size: 40px;
//    font-weight: 700;
//    text-transform: uppercase;
//    margin: 0;
//}
//.slider-text-5.style-1 .slider-text-info > h1 {
//    color: #ffffff;
//    font-size: 70px;
//    font-weight: 800;
//    line-height: 55px;
//    margin: 0;
//    text-transform: uppercase;
//}
//.slider-text-5 .slider-text-info .slier-collecton-btn {
//    border: 2px solid #ffffff;
//    color: #ffffff;
//    display: inline-block;
//    height: 50px;
//    line-height: 44px;
//    margin-top: 40px;
//    padding: 0 30px;
//    text-transform: uppercase;
//    -webkit-transition: 0.3s ease-in-out;
//    transition: 0.3s ease-in-out;
//}
//.slider-text-5 .slider-text-info .slier-collecton-btn:hover {
//    border: 2px solid #222;
//    color: #fff;
//    background: #222
//}
//.slider-text-5.style-2 .slider-text-info .slier-collecton-btn {
//    border: 2px solid #333333;
//    color: #333333;
//    line-height: 44px;
//}
//.slider-text-5.style-2 .slider-text-info .slier-collecton-btn:hover{
//    color: #fff;
//    border: 2px solid #333;
//}
//.slider-text-5.style-2 {
//    padding: 209px 0;
//}
//.slider-text-5.style-2 .slider-text-info > p {
//    color: #333333;
//    font-size: 40px;
//    text-transform: uppercase;
//}
//.slider-text-5.style-2 h1 {
//    font-size: 64px;
//    font-weight: 600;
//    line-height: 50px;
//    text-transform: uppercase;
//}
//.slider-text-5.style-2 .slider-text-info > h2 {
//    font-size: 40px;
//}
.slider-active .owl-nav button i {
    color: #ffffff;
    font-size: 20px;
}

.slider-active .owl-nav button {
    background: rgba(0, 0, 0, 0.15) !important;
    color: #ffffff;
    height: 70px;
    left: 10px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 40px;
    border-radius: 25px;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.slider-active .owl-nav button:hover {
    background: #c97178 !important;
}

.slider-active .owl-nav button.owl-next {
    left: auto;
    right: 10px;
}

.slider-active .owl-dots {
    bottom: 20px;
    display: inline-block;
    left: 100px;
    position: absolute;
}

.slider-active .owl-dots .owl-dot {
    background: #fff;
    border-radius: 50px;
    height: 10px;
    margin-right: 8px;
    width: 10px;
}

.slider-active .owl-dots .owl-dot.active {
    background: #ffffff;
    width: 35px;
}