/***
====================================================================
    pre-footer
====================================================================
 ***/
.pre-footer {
    background-color: $primary;
    padding-top: 20px;
    .inner {
        background-color: #f7f7f7;
    }

    .pre-footer-lace {
        height: 40px;
        margin-bottom: 30px;
    }
}

.pre-footer-col {
    .icon {
        text-align: center;
        height: 80px;
        img {
            width: 80px;
            display: inline-block;
        }

        margin-bottom: 5px;
    }
    .title {
        font-family: $lobster_font;
        color: $primary;
        text-shadow: 2px 2px 0 $secondary;
        font-size: 2.2rem;
    }
    &.payment {
        .icon {
            img {
                width: 70px;
            }

        }
    }
    &.shipping {

    }
    .text {
        color: #888;
    }
    .form {
        margin-top: 30px;
    }
}

/***
====================================================================
    footer
====================================================================
 ***/
.page-footer {
    overflow: hidden;
    a {
        color: $secondary;
    }
}
.page-footer-lace{
    height: 40px;
}
.footer-col {
    color: #fff;
    .title {

        font-size: 2.2rem;
        color: #fff;
    }
    table {
        td {
            line-height: 1;
            padding: 5px;
        }
    }
    .social-line {
        a {
            .fa {
                font-size: 24px;
            }
            color: #fff;
            &:hover {
                text-decoration: none;
                color: $secondary;
            }
        }
    }
}

.bottom-line {
    .signature a {
        color: #fff;
        background-image: url("../img/apaone-logo-white.png");
        background-repeat: no-repeat;
        padding-left: 20px;
        background-position: 0;
        text-decoration: none;
    }
}

.footer-bg-logo {
    opacity: .18;
    position: absolute;
    top: -20px;
    height: 320px;
    width: 558px;
    z-index: 2;
    background-image: url("../img/footer-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.footer-bg-top {
    position: relative;
    z-index: 5;
}