.btn {
  text-transform: uppercase;
  letter-spacing: 1px;

}

.btn-no-wrap {
  //padding: 50px;
  text-align: center;
  white-space: normal;
  color: #000;
  span {
    text-align: center;
    display: inline-block;
    padding: 5px 20px;
  }
}
